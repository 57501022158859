////////// COLOR SYSTEM //////////

$blue: #5e50f9;
$indigo: #6610f2;
$purple: #6a008a;
$pink: #e91e63;
$red: #f96868;
$orange: #f2a654;
$yellow: #f6e84e;
$green: #46c35f;
$teal: #58d8a3;
$cyan: #57c7d4;
$black: #000;
$white: #ffffff;
$white-smoke: #f2f7f8;
$violet: #41478a;
$darkslategray: #2e383e;
$dodger-blue: #3498db;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: #434a54,
  gray-light: #aab2bd,
  gray-lighter: #e8eff4,
  gray-lightest: #e6e9ed,
  gray-dark: #0f1531,
  black: #000000,
);

$theme-colors: (
  primary: #0033c4,
  secondary: #a0a0a0,
  success: #00d284,
  info: #00cff4,
  warning: #ff5730,
  danger: #ff0854,
  light: #f8f9fa,
  dark: #3e4b5b,
);

////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg: #f8f9fb;
$footer-bg: $content-bg;
$footer-color: color(dark);
$border-color: #e4e9f0;
$circle-border: $white;
////////// COLOR VARIABLES //////////

////////// SOCIAL COLORS //////////

$social-colors: (
  twitter: #2caae1,
  facebook: #3b579d,
  google: #dc4a38,
  linkedin: #0177b5,
  pinterest: #cc2127,
  youtube: #e52d27,
  github: #333333,
  behance: #1769ff,
  dribbble: #ea4c89,
  reddit: #ff4500,
);

////////// SOCIAL COLORS //////////

////////// FONTS//

$type-1: "Open Sans", sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$default-font-size: 0.875rem; // 14px as base font size

$text-muted: #6c7293;
$body-color: #030303;

////////// FONT VARIABLES //////////

////////// SIDEBAR ////////
$sidebar-width-lg: 260px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-light-bg: $white;
$sidebar-light-menu-color: #3e4b5b;
$sidebar-light-submenu-color: #3e4b5b;
$sidebar-light-menu-active-bg: #1a55e3;
$sidebar-light-menu-active-color: $white;
$sidebar-light-menu-hover-bg: darken($sidebar-light-bg, 1%);
$sidebar-light-menu-hover-color: $white;
$sidebar-light-submenu-hover-color: darken($sidebar-light-submenu-color, 20%);
$sidebar-light-submenu-active-color: #dfe2e8;
$sidebar-light-menu-icon-color: #6a6b83;
$sidebar-light-menu-title-color: #141212;
$sidebar-light-menu-arrow-color: #423a8e;
$sidebar-light-menu-border-color: #f2edf3;

$sidebar-dark-bg: #18151e;
$sidebar-dark-menu-color: $white;
$sidebar-dark-menu-active-bg: #1a55e3;
$sidebar-dark-menu-active-color: $white;
$sidebar-dark-menu-hover-bg: $sidebar-dark-menu-active-bg;
$sidebar-dark-menu-hover-color: color(white);
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: darken($sidebar-dark-submenu-color, 30%);
$sidebar-dark-menu-icon-color: $white;
$sidebar-dark-menu-arrow-color: $white;

$sidebar-menu-font-size: $default-font-size;
$sidebar-menu-padding-y: 10px;
$sidebar-menu-padding-x: 2.25rem;

$sidebar-submenu-font-size: 0.8125rem;
$sidebar-submenu-item-padding: 0.75rem 2rem 0.75rem 15px;

$sidebar-icon-font-size: 20px;
$sidebar-arrow-font-size: 0.625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0.75rem 1.625rem 0.75rem 1.188rem;

$sidebar-mini-menu-padding: 0.8125rem 1rem 0.8125rem 1rem;

$sidebar-icon-only-menu-padding: 0.5rem 1.625rem 0.5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$rtl-sidebar-submenu-padding: 0 1.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-menu-hover-bg;
$icon-only-menu-bg-dark: $sidebar-dark-menu-hover-bg;

///////// SIDEBAR ////////

$bullet-line-list-shape-bg: color(white) !default;

///////// CARD ////////
$card-bg: #fff;
$card-description-color: #76838f;
$card-title-color: #313131;
$card-bg-varient: #fff;
///////// CARD ////////

//////// TABLES ////////
$table-accent-bg: $content-bg;
$table-hover-bg: $content-bg;
$table-cell-padding: 0.9375rem;
$table-border-color: $border-color;
$table-inverse-bg: #2a2b32;
$table-inverse-color: color(white);
$thead-bg: $content-bg;
//////// TABLES ////////

////////// MODALS VARIABLES //////////
$modal-content-bg: $content-bg;
////////// MODALS VARIABLES //////////

///////// NAVBAR ////////
$navbar-bg: theme-color(primary);
$navbar-height: 60px;
$navbar-menu-color: #fff;
$navbar-font-size: 0.875rem;
$navbar-icon-font-size: 1.25rem;
$mail-head-bg: #303a40;
$u-name-color: $black;
$file-name: $black;
///////// NAVBAR ////////

///////// BUTTONS ////////

$button-fixed-width: 150px;
$btn-padding-y: 10px;
$btn-padding-x: 20px;
$btn-line-height: 1;

$btn-padding-y-xs: 0.5rem;
$btn-padding-x-xs: 0.75rem;

$btn-padding-y-sm: 6px;
$btn-padding-x-sm: 7px;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 3rem;

$btn-font-size: 12px;
$btn-font-size-xs: 0.625rem;
$btn-font-size-sm: 0.875rem;
$btn-font-size-lg: 0.875rem;

$btn-border-radius: 4px;
$btn-border-radius-xs: 4px;
$btn-border-radius-sm: 4px;
$btn-border-radius-lg: 4px;

///////// BUTTONS ////////

////////// TOOLTIP VARIABLES //////////

//default styles
$tooltip-font-size: 0.75rem;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.75rem;
$tooltip-border-radius: 0.375rem;

////////// TOOLTIP VARIABLES //////////

/////////  FORMS /////////

$input-bg: color(white);
$input-border-radius: 2px;
$input-placeholder-color: #c9c8c8;
$input-font-size: 0.8125rem;

$input-padding-y: 0.94rem;
$input-padding-x: 1.375rem;
$input-line-height: 1;

$input-padding-y-xs: 0.5rem;
$input-padding-x-xs: 0.75rem;

$input-padding-y-sm: 0.5rem;
$input-padding-x-sm: 0.81rem;

$input-padding-y-lg: 0.94rem;
$input-padding-x-lg: 1.94rem;

$input-height: 2.875rem;
$input-height-sm: 2.575rem;
$input-height-lg: 3.175rem;

///////// FORMS /////////

////////  DROPDOWNS ///////
$dropdown-border-color: $border-color;
$dropdown-divider-bg: $border-color;
$dropdown-link-color: $body-color;
$dropdown-header-color: $body-color;
$dropdown-menu-box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
$dropdown-hover-bg: $text-muted;
////////  DROPDOWNS ///////

////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$boxed-container-width: 1200px;
$border-property: 1px solid $border-color;
$card-spacing-y: 30px;
$card-padding-y: 30px;
$card-padding-x: 28px;
$card-border-radius: 3px;
$grid-gutter-width: 30px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
////////// OTHER VARIABLES //////////

////////// BREAD CRUMBS VARIABLES //////////

// default styles
$breadcrumb-padding-y: 0.56rem;
$breadcrumb-padding-x: 1.13rem;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-font-size: $default-font-size;
$breadcrumb-bg: transparent;
$breadcrumb-border-color: $border-color;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider: "/";

// custom styles
$breadcrumb-custom-padding-y: 0;
$breadcrumb-custom-padding-x: 0;
$breadcrumb-custom-item-padding-y: 0.56rem;
$breadcrumb-custom-item-padding-x: 10px;
$breadcrumb-custom-item-color: $black;
$breadcrumb-item-bg: #dbe3e6;
////////// BREAD CRUMBS VARIABLES //////////

////////// MODALS VARIABLES //////////
$modal-inner-padding: .9375rem;
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 30px;
$modal-title-line-height: $line-height-base;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, 0.5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;

$modal-header-border-color: $border-color;
$modal-content-border-color: $border-color;
$modal-footer-border-color: $border-color;

$modal-header-border-width: $border-width;
$modal-content-border-width: $border-width;
$modal-footer-border-width: $border-width;

$modal-header-padding-x: 26px;
$modal-header-padding-y: 25px;

$modal-body-padding-x: 26px;
$modal-body-padding-y: 35px;

$modal-footer-padding-x: 31px;
$modal-footer-padding-y: 15px;

$modal-lg: 90%;
$modal-md: 500px;
$modal-sm: 300px;
$modal-transition: transform 0.4s ease;

////////// MODALS VARIABLES //////////

/////////  TABS VARIABLES //////////
$nav-tabs-border-color: #ebedf2;
$nav-tabs-link-bg: #f6f8fa;
$nav-tabs-link-color: #000000;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color
  $nav-tabs-border-color;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color
  $nav-tabs-border-color $nav-tabs-link-active-bg;
$nav-pills-custom-bg: #fcfcfd;
////////   TABS VARIABLES /////////
$accent-color: $white;
$chat-container-height: 100%s;
$text-gray: gray;
$bullet-line-list-shape-bg: color(white) !default;
$arrow-color: $white;
$tab-head-bg: darken(#f2edf3, 2%);
$accordian-shadow-color: rgba(230, 234, 236, 0.35);
$landing-bg: #1a142dfa;
$private-btn-bg: #edeff5;
$circle-img-border: $white;
$bottom-submenu-bg: $white;
$chat-bottom-btn: $white;
$form-focus-bg: $white;
$form-focus-bg: $white;
$form-focus-color: $black;
$landing-bg-color: $white;
$mail-hover-color: rgba(240, 244, 249, 0.8);

///////// SETTINGS PANEL ////////
$settings-panel-width: 300px;
///////// SETTINGS PANEL ////////

////////   CALENDAR  //////////////
$fullcalendar-color: #a8b2b9;
////////   CALENDAR  //////////////

////////   MISC  //////////////
$page-title-color: $body-color;
////////   MISC  //////////////

//////////// CUSTOM VARIABLES /////////////
$border-right-color: #313131;
$icons-page-color: theme-color(primary);
